/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactMapGL, { Marker } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { Box } from 'rebass/styled-components'

import CustomMapController from './CustomMapController'
import MapPin from '../../images/map-pin.svg'

const mapController = new CustomMapController()

const ostrava = {
  latitude: 49.82799039370804,
  longitude: 18.230752737554234,
  zoom: 12,
  pitch: 30,
}

const Map = ({ geojson }) => {
  const defaultViewport = ostrava
  const [viewport, setViewport] = useState({
    height: 400,
    ...defaultViewport,
  })

  return (
    <Box
      sx={{
        bg: '#ece0ca',
        height: viewport.height,
        borderRadius: '0.5rem',
        overflow: 'hidden',
        boxShadow: 'card',
      }}
    >
      <ReactMapGL
        {...viewport}
        width="100%"
        controller={mapController}
        mapStyle={process.env.GATSBY_MAPBOX_MAP_STYLE}
        mapboxApiAccessToken={process.env.GATSBY_MAPBOX_API_TOKEN}
        onViewportChange={(_viewport) => setViewport(_viewport)}
      >
        {geojson &&
          geojson.features.map((marker) => {
            const [latitude, longitude] = marker.geometry.coordinates
            const { title } = marker.properties

            return (
              <Marker
                key={title}
                title={title}
                latitude={latitude}
                longitude={longitude}
                offsetLeft={-47}
                offsetTop={-47}
                options={{
                  anchor: 'bottom',
                }}
              >
                <img
                  src={MapPin}
                  alt=""
                  style={{ width: '5.875rem', height: '5.875rem' }}
                />
              </Marker>
            )
          })}
      </ReactMapGL>
    </Box>
  )
}

Map.propTypes = {
  geojson: PropTypes.shape({
    type: PropTypes.string.isRequired,
    features: PropTypes.array.isRequired,
  }),
}

Map.defaultProps = {
  geojson: null,
}

export default Map
