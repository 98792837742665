/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import { MapController } from 'react-map-gl'

class CustomMapController extends MapController {
  _onWheel() {
    return false
  }
}

export default CustomMapController
