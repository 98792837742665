const geojson = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [49.82799039370804, 18.230752737554234],
      },
      properties: {
        title: 'Czech Heat service s.r.o.',
        description: 'Na Lánech 58/21, 709 00 Ostrava-Nová Ves',
      },
    },
  ],
}

export default geojson
