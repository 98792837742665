import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Heading, Flex, Image, Link } from 'rebass/styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/hero'
import Section from '../components/section'
import Container from '../components/container'
import IconMobilePhone from '../images/icons/mobile.svg'
import IconEmail from '../images/icons/email.svg'
import Map from '../components/map'
import geojson from '../data/map-data'

const ContactItem = (props) => (
  <Flex sx={{ py: 2, alignItems: 'center' }} {...props} />
)

const Icon = (props) => <Image sx={{ mr: 2 }} {...props} />

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "contact.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      site {
        siteMetadata {
          title
          address {
            street
            city
            postal
            country
          }
        }
      }
    }
  `)
  const { address } = data.site.siteMetadata
  const { street, city, postal, country } = address

  return (
    <Layout>
      <SEO title="Kontakty" />

      <Hero
        title="Kontakty"
        subtitle="Neváhejte nás kontaktovat!"
        image={data.image}
      />

      <Section>
        <Container>
          <Heading as="h2" sx={{ mb: 4, fontSize: 5 }}>
            Czech Heat service s.r.o.
          </Heading>

          <Flex
            sx={{
              flexDirection: ['column', 'column', 'row'],
            }}
          >
            <Box sx={{ width: ['100%', '50%'] }}>
              <Heading as="h3" sx={{ mb: 3 }}>
                Sídlo
              </Heading>

              <Box sx={{ mb: 4 }} as="ul">
                <li>{street}</li>
                <li>{`${postal} ${city}`}</li>
                <li>{country}</li>
              </Box>

              <Box as="ul">
                <ContactItem>
                  <Icon src={IconMobilePhone} alt="" aria-hidden />
                  Mobil:&nbsp;
                  <a href="tel:+420777580564">+420 777 580 564</a>
                </ContactItem>
                <ContactItem>
                  <Icon src={IconEmail} alt="" aria-hidden />
                  E-mail:&nbsp;
                  <a href="mailto:info@czech-heat.cz">info@czech-heat.cz</a>
                </ContactItem>
              </Box>
            </Box>

            <Box sx={{ width: ['100%', '50%'] }}>
              <Heading as="h3" sx={{ mb: 3 }}>
                Pohotovostní služba pro TEPLO Kopřivnice
              </Heading>

              <Link variant="primarySmall" href="tel:+420602684909">
                +420 602 684 909
              </Link>
            </Box>
          </Flex>
        </Container>
      </Section>

      <Map geojson={geojson} />
    </Layout>
  )
}

export default IndexPage
